@import "vars";

//@media only screen and (max-width : 1200px)
@media only screen and (max-width : 992px) {
    .header {
        &-search-callback {
            display: none; } }
    .main-menu {
        padding-top: 5px;
        padding-bottom: 5px;
        .container {
            padding-left: 0;
            padding-right: 0;
            .header-search {
                float: right;
                margin-right: 15px; }
            button.toggle-menu {
                margin-left: 15px;
                padding: 5px 10px;
                .fa {
                    font-size: 18px;
                    line-height: 1; } }
            > ul {
                display: none;
                width: 100%;
                margin-top: 7px;
                background-color: $white-color;
                > li {
                    display: block;
                    position: relative;
                    z-index: 2;
                    & + li {
                        border-left: none; }
                    > ul {
                        display: block;
                        position: static;
                        z-index: 1;
                        background: none;
                        border: none;
                        padding: 0;
                        margin-left: 25px;
                        > li {
                            margin-top: 0;
                            & + li {
                                margin-top: 2px; }
                            > a {
                                color: $black-color;
                                text-decoration: none;
                                font-size: 15px;
                                &:hover {
                                    color: $accent-color; } } } }
                    > a {
                        text-align: left;
                        padding: 4px 15px 1px 15px; } } } } } }

@media only screen and (max-width: 768px) {
    .header {
        padding-top: 3px;
        &-phone, &-regim {
            display: block;
            text-align: center;
            font-size: 14px; }
        &-search-callback {
            display: none; }
        &-cart {
            display: flex;
            align-items: center;
            .fa {
                font-size: 14px;
                border: 1px solid $white-color;
                padding: 4px;
                margin-right: 4px;
                border-radius: 50%;
                display: block; }
            .d-sm-block {
                display: none!important;
                margin-left: 0; }
            #cart, .button-callback {
                text-align: center; }
            .btn.btn-primary {
                text-align: center;
                font-size: 13px;
                font-weight: 300;
                padding: 4px 5px 3px 5px;
                vertical-align: middle;
                width: 80%;
                margin-left: 15px; }
            #cart {
                padding-left: 3px;
                .cart-total {
                    white-space: nowrap; } } } }
    .slideshow-wrap {
        display: none; }
    .module-product-block {
        margin-top: 15px;
        h2 {
            text-align: center; } }
    .product-card-block {
        text-align: center;
        .product-card {
            display: inline-block; } }
    ul.pagination {
        margin-bottom: 10px; }
    .flycart {
        &-buttons {
            display: block;
            > div {
                display: block;
                text-align: center;
                &:last-of-type {
                    text-align: center;
                    margin-top: 15px; } } } }
    .right-panel {
        text-align: center;
        .product-attribute {
            td:first-child {
                text-align: left; } } }

    .simplecheckout-cart-products {
        .item {
            display: block;
            .product {
                width: 100%;
                display: inline-block;
                vertical-align: middle; }
            .quantity,
            .total {
                width: auto;
                margin-left: 110px;
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                span {
                    display: inline; } }
            .remove {
                display: inline-block;
                vertical-align: middle; } } }
    img {
        max-width: 100%; }

    .panel-manage-products {
       .sort-limit {
           .limit {
               display: none; } } }


    .footer {
        .row > div {
            margin-bottom: 15px; } } }

@media only screen and (max-width: 575px) {
    .header {
        &-phone, &-regim {
            display: flex;
            font-size: 15px; }
        &-cart, &-regim {
            justify-content: flex-end; } } }

