.simple-content {
	margin: 0px 5px;
}
.simple-content fieldset {
	text-align: left;
	border: none;
	padding: 0;
	margin: 0;
}
.simple-content .name {
	float: none !important;
}
.simple-content .button, #simplecheckout_login .button {
	margin: 0px !important;
}
.simple-content .buttons {
	margin: 0px 0px 15px 0px;
	border: none;
}
.simple-content .form-control {
	width: 100%;
}
#simplecheckout_login .buttons {
	margin: 0;
	border: none;
	background: inherit !important;
	padding: 0px !important;
}
.simplecheckout {
	width:100%;
}
.simplecheckout:after {
	content:"";
	display:block;
	clear:both;
}
.simplecheckout-three-column {
	float:left;
	margin-right:2%;
	min-width: 200px;
	max-width: 500px;
	width:32%;
}
.simplecheckout-three-column + .simplecheckout-three-column + .simplecheckout-three-column {
	margin-right:0px;
	float: right;
}
@media only screen and (max-width:768px) {
	.simplecheckout-three-column {
		display:block;
		float: none;
		min-width: auto !important;
		max-width: 1900px !important;
		width: 100%;
	}
}
.simplecheckout-left-column {
	float:left;
	margin-right:10px;
	min-width: 280px;
	max-width: 900px;
	width:52%;
}
.simplecheckout-right-column {
	float:right;
	min-width: 280px;
	max-width: 900px;
	width:46%;
}
@media only screen and (max-width:1024px) {
	.simplecheckout-left-column {
		display:block;
		float: none;
		min-width: auto !important;
		max-width: 1900px !important;
		width: 100% !important;
	}
	.simplecheckout-right-column {
		display:block;
		float: none;
		min-width: auto !important;
		max-width: 1900px !important;
		width: 100% !important;
	}
}
@media only screen and (max-width:1200px) {
	.simplecheckout label.col-sm-2 {
		padding-left: 5px;
		padding-right: 5px;
	}
}
@media only screen and (max-width:768px) {
	.simplecheckout label.col-sm-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.simplecheckout-block {
	clear: both;
	margin-bottom: 20px;
}

@media only screen and (min-width:1200px) {
	.simplecheckout-block-content>fieldset:nth-of-type(2),
	.simplecheckout-block-content>fieldset:nth-last-of-type(2) {
		width: 49%;
		margin-right: 1%;
		float: left;
		text-align: left;
	}
}
.simplecheckout-block-content:after {
	content:"";
	display:block;
	clear:both;
}
.simpleregister {
	margin-bottom: 5px;
}
.simpleregister-block-content {
	margin-bottom: 10px;
	padding: 10px;
}
@media only screen and (min-width:1200px) {
	.simpleregister-block-content>fieldset:nth-of-type(2),
	.simpleregister-block-content>fieldset:nth-last-of-type(2) {
		width: 49%;
		margin-right: 1%;
		float: left;
		text-align: left;
	}
}
.simpleregister-block-content:after {
	content:"";
	display:block;
	clear:both;
}
.simpleregister-block-content .simplecheckout-table-form-left {
	width: 50%;
}
@media only screen and (max-width:600px) {
	.simpleregister-block-content .simplecheckout-customer {
		width: 100%;
	}
}
.simpleregister-button-block {
	overflow: auto;
	margin-bottom: 20px;
	padding: 6px;
	text-align:right;
}
.simpleregister-button-right {
	float: right;
	text-align: right;
}
.simpleregister-button-left {
	float: left;
	text-align: left;
}
.simplecheckout-methods-table {
	width: 100%;
}
.simplecheckout-methods-table td.code {
	width: 1px;
}
.simplecheckout-methods-table td.title {
	padding: 5px;
}
.simplecheckout-methods-table td.title label {
	display: block !important;
}
.simplecheckout-methods-table td.quote {
	width: 15%;
	text-align: right;
}
.simplecheckout-methods-description {
	padding: 5px;
	margin: 5px 0px;
}
.simplecheckout-required {
	color: #FF0000;
	font-weight: bold;
}
.simplecheckout-button-block {
	clear: both;
	overflow: auto;
	margin-bottom: 20px;
	padding: 6px;
	text-align:center;
}
.simplecheckout-button-right {
	float: right;
	text-align: right;
}
.simplecheckout-button-left {
	float: left;
	text-align: left;
}
#agreement_checkbox {
	display: block;
	margin-bottom: 5px;
}
#agreement_checkbox input[type="checkbox"]{
	margin-right: 5px;
}
.simplecheckout-button {
	display: inline-block;
	padding: 6px 12px 6px 12px;
	-webkit-border-radius: 7px 7px 7px 7px;
	-moz-border-radius: 7px 7px 7px 7px;
	border-radius: 7px 7px 7px 7px;
	background: url('../image/button.png') top left repeat-x;
	-webkit-box-shadow: 0px 2px 2px #DDDDDD;
	-moz-box-shadow: 0px 2px 2px #DDDDDD;
	box-shadow: 0px 2px 2px #DDDDDD;
	color: #FFFFFF;
	text-decoration: none;
	font-weight: bold;
	font-size: 12px;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 12px;
	cursor: pointer;
	-khtml-border-radius: 7px 7px 7px 7px;
}
.simplecheckout-button-left .button {
	margin: 0;
}
@media only screen and (max-width:780px) {
	.simplecheckout-button-left {
		display: none;
	}
}
.simplecheckout-button:hover {
   background: url('../image/button-active.png') repeat-x;
}

#simplecheckout_customer .simplecheckout-block-content {
	text-align: center;
}
.simplecheckout-login {
	display: inline-block;
}
.simplecheckout-login-left {
	width:50%;
	text-align: left;
}
.simplecheckout-login-right {
	width:50%;
	text-align:left;
}
.simplecheckout-login-right .button {
	float: left;
	margin: 0;
}
.simplecheckout-login-right input {
	max-width: 160px !important;
	text-align:left;
}

.simplecheckout-three-column .simplecheckout-cart td.name .image {
	display: block;
}
.simplecheckout-scroll{
	overflow-y:scroll;
	max-height:300px;
}
.simplecheckout-error-text {
	display: block;
	margin-top: 3px;
	color: #FF0000;
}
.simplecheckout-warning-text {
	margin: 5px 0;
	font-weight: bold;
}
.simplecheckout-warning-block {
	clear: both;
	margin-bottom: 15px;
	padding: 10px 10px 10px 33px;
	border: 1px solid #F8ACAC;
	border-radius: 5px 5px 5px 5px;
	color: #555555;
	text-align: left;
}
.simpleregister-warning-block {
	margin-bottom: 15px;
	padding: 10px 10px 10px 33px;
	border: 1px solid #F8ACAC;
	border-radius: 5px 5px 5px 5px;
	color: #555555;
	text-align: left;
}
.simplecheckout-proceed-payment {
	display: none;
	margin-bottom: 10px;
	padding: 10px;
	text-align: center;
	font-weight: bold;
}
#simple_payment_address_block {
	margin-top: 15px;
}
.simplecheckout-customer-same-address {
	text-align: left;
	margin: 15px 0px;
}
#simplecheckout_login {
	margin: auto;
	text-align: center;
}
#simplecheckout_payment_form {
	padding: 5px;
}
#simplecheckout_payment_form .checkout-content {
	display: block;
}
#simplecheckout_payment_form div.buttons {
	display: none;
}
#simplecheckout_login .simplecheckout-block-content {
	padding: 5px !important;
}
#simple_login_layer {
	position:absolute;
	top:0;
	left:0;
	z-index:99990;
	display:none;
	width:100%;
	background-color:#000000;
}
#simple_login {
	position:fixed;
	z-index:99991;
	display: none;
	padding:5px;
	width:300px;
	height:250px;
	border:1px solid #FFD9FF;
	-webkit-border-radius:7px 7px 7px 7px;
	-moz-border-radius:7px 7px 7px 7px;
	border-radius:7px 7px 7px 7px;
	background:none repeat scroll 0 0 #FFFFFF;
	-khtml-border-radius:7px 7px 7px 7px;
}
#simple_login:after {
	content:"";
	display:block;
	clear:both;
}
#simple_login_header {
	padding: 0px 0px;
	text-align: right;
}
#simple_login_header img {
	margin: 0 0 0 auto;
}
.you-will-be-registered {
	font-weight: bold;
}
.simplecheckout label, .simpleregister label {
	display: inline-block !important;
	margin: 0;
}
.simplecheckout label input, .simpleregister label input {

}
#simple_summary {
	display: none;
}
#simplecheckout_step_menu {
	margin-bottom: 10px;
}
.simple-step {
	display: inline-block;
	padding: 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	background-color: #D9D9D9;
	color: #000000;
	font-size: 13px;
	-khtml-border-radius: 5px 5px 5px 5px;
	cursor: pointer;
	margin-bottom: 5px;
}
.simple-step-current {
	display: inline-block;
	padding: 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	background-color: #38B0E3;
	color: #FFFFFF;
	font-size: 13px;
	-khtml-border-radius: 5px 5px 5px 5px;
}
.simple-step-delimiter {
	display: inline-block;
	vertical-align: middle;
	line-height: 13px;
	margin: 0px 5px;
}
.simple-step-completed {
	display: inline-block;
	padding: 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	background-color: #38B0E3;
	color: #FFFFFF;
	font-size: 13px;
	-khtml-border-radius: 5px 5px 5px 5px;
}
.simple-step-completed a {
	color: #FFFFFF !important;
	text-decoration: none !important;
	font-size: 13px !important;
}
.simplecheckout-summary-info {
	margin-top: 15px !important;
}
.simplecheckout-summary-info th.name {
	text-align: left;
}
.simplecheckout-summary-info td {
	text-align: left;
}
.simplecheckout-cart-buttons .buttons {
	border: none !important;
	padding: 0 !important;
}
#simple_login .simplecheckout-block-content {
	border: none !important;
}
#temp_flat_container {
	text-align: center;
	margin: 10px 0px 20px 0px;
}
#simplecheckout_comment .simplecheckout-block-content {
	border: 0 !important;
	padding: 0 !important;
}
#simplecheckout_comment textarea {
	width: 100% !important;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	min-height: 100px;
}
.simplecheckout-table-form {
	width: 49%;
	margin-right: 1%;
	float: left;
	text-align: left;
}
@media only screen and (max-width:768px) {
	.simplecheckout-table-form {
		width: 100%;
		margin-right: 0%;
		float: none;
	}
}
.simplecheckout-three-column .simplecheckout-table-form,
.simplecheckout-left-column .simplecheckout-table-form,
.simplecheckout-right-column .simplecheckout-table-form {
	width: 100%;
	margin-right: 0%;
	float: none;
}
.simplecheckout-table-form-left {
	width: 50%;
	text-align: left;
	padding: 3px;
	vertical-align: top;
}
.simplecheckout-table-form-right {
	width: 50%;
	text-align: left;
	padding: 3px;
}
.simplecheckout-table-form-right input[type=text],
.simplecheckout-table-form-right input[type=password],
.simplecheckout-table-form-right input[type=email],
.simplecheckout-table-form-right input[type=tel],
.simplecheckout-table-form-right input[type=phone],
.simplecheckout-table-form-right input[type=date],
.simplecheckout-table-form-right input[type=time],
.simplecheckout-table-form-right select,
.simplecheckout-table-form-right textarea {
	width: 100%;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0px !important;
}
.simplecheckout-table-form-right label {
	display: block !important;
}
.simplecheckout-table-form-right label input {
	margin: 0px 5px 0px 0px !important;
}
.checkout-heading:after {
	content:"";
	display:block;
	clear:both;
}
.col-sm-12 .checkout-heading {
	background-color: #f5f5f5;
	border-color: #ddd;
	color: #333;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	padding: 10px 15px;
}
.checkout-heading span {
	display: inline-block !important;
	float: left;
}
.checkout-heading-button {
	float: right !important;
	display: inline-block !important;
}
.simplecheckout-select-address {
	text-align: left;
	margin-bottom: 10px;
}
.simple-captcha-container {
	margin-top: 5px;
}
.simplecheckout-tooltip {
	display: none;
}
#easyTooltip {
	padding:5px;
	border:1px solid #ccc;
	background:#fde910;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-khtml-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-webkit-box-shadow: 0px 2px 2px #DDDDDD;
	-moz-box-shadow: 0px 2px 2px #DDDDDD;
	box-shadow: 0px 2px 2px #DDDDDD;
	opacity:1 !important;
}
#easyTooltip:before {
	content:'';
	position:absolute;
	left:10px;
	top:-8px;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 8px solid #fde910;
}

.simple-content .table-responsive{
	margin-bottom: 0 !important;
	border: none !important;
}

.row-customer_register .radio {
	display: inline-block !important;
	margin-right: 10px;
}