@import "fonts";
@import "libs";
@import "vars";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%;
	background-color: #fff;
	margin: 0;
	padding: 0; }

body {
	min-width: 320px;
	font-weight: 300;
	position: relative;
	line-height: 1.5;
	font-family: $text-font;
	font-size: 14px;
	color: $black-color;
	overflow-x: hidden;
	opacity: 1; }
button,
a {
	outline: none;
	border-radius: 0;
	cursor: pointer;
	transition: .2s all; }

input,
textarea {
	outline: none;
	border: none;
	background: none;
	border: 1px solid #c8c8c8;
	background-color: $white-color;
	padding: 5px 15px;
	transition: .2s all;
	font-size: 13px;
	&:focus {
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.075); } }
fieldset {
	border: none;
	padding: 0;
	margin: 0; }

a {
	color: $accent-color; }
h1, h2, h3 {
	font-weight: 300;
	font-family: $header-font;
	text-transform: uppercase;
	margin: 0;
	line-height: 1.2; }
h1 {
	margin-bottom: 15px; }
h2 {
	margin-bottom: 7px;
	a {
		text-decoration: none;
		color: $black-color;
		&:hover {
			color: $accent-color; } } }
.btn {
	text-decoration: none;
	background: none;
	border: none;
	outline: none;
	display: inline-block;
	border-radius: 0;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 13px;
	line-height: 1;
	&-primary {
		background-color: $accent-color;
		color: $black-color;
		&:hover {
			background-color: darken($accent-color, 8%); } }
	&-default {
		border: 1px $black-color solid;
		color: $black-color;
		background-color: $white-color;
		&:hover {
			background-color: $black-color;
			color: $white-color; } } }
a.btn {
	padding: 5px 15px; }

button.btn,
input.btn {
	padding: 5px 15px; }

ul {
	&.list-unstyled,
	&.list-inline {
		list-style: none;
		margin: 0;
		padding: 0; }
	&.list-inline {
		li {
			display: inline-block;
			& + li {
				margin-left: 7px; } } } }

.text-left {
	text-align: left; }
.text-right {
	text-align: right; }
.text-danger {
	color: $red-color;
	font-size: 13px; }
.header {
	padding: 7px 0;
	background-color: $grey-color;
	color: $white-color;
	a {
		text-decoration: none; }
	&-phone, &-regim {
		display: flex;
		align-items: center;
		font-size: 15px;
		a {
			color: $white-color; }
		.fa {
			margin-right: 10px;
			font-size: 22px;
			color: $accent-color; } }
	&-search-callback {
		text-align: center; }
	&-search {
		margin-bottom: 7px;
		position: relative;
		z-index: 1;
		input {
			width: 100%; }
		button {
			position: absolute;
			z-index: 1;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border: none;
			background: none;
			color: $black-color;
			.fa {
				font-size: 18px; } } }
	&-profile-cart {
		text-align: right; }
	&-profile {
		margin-bottom: 7px;
		a {
			border-bottom: 1px solid;
			line-height: 1;
			&:hover {
				color: $white-color; } }
		a + a {
			margin-left: 10px; } }
	&-cart {
		a {
			color: $white-color;
			&:hover {
				color: $accent-color; } }
		.fa {
			font-size: 18px;
			border: 2px solid $white-color;
			padding: 5px;
			margin-right: 7px;
			border-radius: 50%; }
		.d-sm-block {
			margin-left: 5px;
			display: inline-block!important; } } }
.main-menu {
	background-color: $light-grey-color;
	border-top: 3px solid $white-color;
	border-bottom: 3px solid $white-color;
	.container {
		> ul {
			display: table;
			width: 100%;
			> li {
				display: table-cell;
				position: relative;
				z-index: 2;
				& + li {
					border-left: 3px solid $white-color; }
				> ul {
					display: none;
					position: absolute;
					z-index: 1;
					background-color: $white-color;
					border: 3px solid darken($light-grey-color, 50%);
					// border-top: none
					// width: 100%
					padding: 7px;
					> li {
						margin-top: 3px;
						position: relative;
						z-index: 2;
						white-space: nowrap;
						> a {
							color: $black-color;
							text-decoration: none;
							font-size: 15px;
							// white-space: nowrap
							&:hover {
								color: $accent-color; } }
						> ul {
							display: none;
							position: absolute;
							top: 0;
							left: 99%;
							background-color: $white-color;
							border: 3px solid darken($light-grey-color, 50%);
							padding: 7px;
							> li {
								margin-top: 3px;
								> a {
									color: $black-color;
									text-decoration: none;
									font-size: 14px;
									white-space: nowrap;
									&:hover {
										color: $accent-color; } } } }
						&:hover {
							> a {
								color: $accent-color; }
							> ul {
								display: block; } } } }
				> a {
					text-decoration: none;
					text-transform: uppercase;
					color: $black-color;
					display: block;
					text-align: center;
					font-size: 16px;
					padding: 4px;
					&:hover, &.active {
						background-color: darken($light-grey-color, 50%);
						color: $white-color; } }
				&:hover {
					> a {
						background-color: darken($light-grey-color, 50%);
						color: $white-color; }
					> ul {
						display: block; } } } } } }


.owl-carousel {
	background: none;
	.owl-wrapper-outer {
		background: none;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-align: center; }
	.owl-item {
		text-align: center;
		.product-card {
			display: inline-block; } }
	.owl-nav, .owl-prev, .owl-next {
		position: absolute; }
	.owl-nav {
		width: 100%;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.owl-prev, .owl-next {
			width: 30px;
			height: 30px;
			display: inline-block;
			border: $accent-color 1px solid;
			transform: rotate(-45deg);
			&.disabled {
				opacity: .3;
				cursor: default; } }
		.owl-prev {
			left: 15px;
			border-right: none;
			border-bottom: none; }
		.owl-next {
			right: 15px;
			border-left: none;
			border-top: none; } }
	.owl-dots {
		position: absolute;
		z-index: 1;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		.owl-dot {
			background-color: $white-color;
			width: 15px;
			height: 15px;
			border-radius: 7.5px;
			display: inline-block;
			margin: 0 3px;
			opacity: .5;
			border: $accent-color 1px solid;
			&.active, &:hover {
				opacity: 1; } } } }

.slideshow {
	margin-bottom: 25px; }

.category-nav {
	box-shadow: 0 1px 1px #cecece;
	margin-bottom: 25px;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: table;
		width: 100%; }
	li {
		text-align: center;
		display: table-cell;
		a {
			color: $black-color;
			text-decoration: none;
			&:hover {
				color: $accent-color; } }
		span {
			display: block;
			margin-bottom: 7px;
			text-transform: uppercase;
			font-size: 15px; }
		img {
			max-width: 100%; } } }

.product-card-grid.owl-carousel,
.image-additional-carousel {
	.owl-nav {
		.owl-prev {
			left: 0; }
		.owl-next {
			right: 0; } }
	.owl-dots {
		display: none; } }

.product-info-mobile-images {
	margin-bottom: 15px;
	.owl-nav {
		.owl-prev {
			left: 0; }
		.owl-next {
			right: 0; } } }

.image-additional-carousel {
	padding: 0 15px;
	.owl-nav {
		.owl-prev, .owl-next {
			width: 15px;
			height: 15px; } } }

.product-card {
	width: 250px;
	text-align: center;
	position: relative;
	h3.name {
		font-weight: 300;
		margin: 0;
		margin-bottom: 7px;
		height: 55px;
		text-align: left;
		overflow: hidden;
		a {
			color: $black-color;
			text-decoration: none;
			text-transform: uppercase;
			&:hover {
				color: $accent-color; } } }
	.description {
		display: none; }
	.stock {
		margin-bottom: 5px; }
	.price {
		font-size: 20px;
		&-old {
			font-size: 15px;
			color: lighten($black-color, 30%);
			position: relative;
			z-index: 1;
			&:after {
				content: '';
				height: 1px;
				background-color: $red-color;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				z-index: 1;
				transform: translateY(-50%); } }
		&-new {
			color: $red-color; } }
	.buttons {
		.btn {
			margin-top: 7px; } } }
.category-subcategory-list {
	margin-bottom: 20px;
	ul {
		li {
			display: inline-block;
			vertical-align: top;
			width: 150px;
			margin: 5px;
			a {
				display: block;
				text-align: center;
				text-decoration: none;
				span {
					display: block;
					&.name {
						font-family: cuprum;
						font-weight: 600;
						color: $black-color; } }
				&:hover {
					span.name {
						text-decoration: underline; } } } } } }

#column-left {
	.product-card {
		h3.name {
			text-align: center; } } }
.product-card-list {
	.product-card-block {
		width: 100%;
		max-width: 100%;
		display: block;
		flex: 0 0 100%; }
	.product-card {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.image {
			width: 20%;
			padding: 2px;
			img {
				max-width: 100%; } }
		.name-description-stock {
			width: 50%;
			.name {
				height: auto; } }
		.price-buttons {
			width: 27%; }
		.description {
			margin-bottom: 5px;
			font-size: 13px;
			display: block;
			text-align: left; }
		.stock {
			color: $accent-color;
			text-align: left; }
		.price {
			margin-bottom: 7px;
			> span {
				display: block;
				text-align: center; } } } }
.product-label {
	position: absolute;
	color: #fff;
	font-size: 10px;
	display: inline-block;
	top: 7px;
	left: 7px;
	letter-spacing: 1px;
	z-index: 10;
	background: #fe6658;
	padding: 4px 8px;
	border-radius: 2px;
	&:before {
		position: absolute;
		top: 7px;
		left: -4px;
		content: "";
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-right: 4px solid #fe6658; } }
.product-info .right-panel .product-label {
	position: static;
	margin-bottom: 5px;
	&:before {
		display: none; } }

.breadcrumb {
	background-color: $light-grey-color;
	margin-bottom: 20px;
	padding: 5px 0;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			font-size: 13px;
			+ li {
				margin-left: 4px;
				&:before {
					content: '';
					font-family: fontawesome;
					margin-right: 4px; } }
			a {
				text-decoration: none;
				color: darken($accent-color, 10%);
				&:hover {} }

			&:first-child {
				a {
					border-bottom: none; } } } } }

.sidebar-category-list.list-unstyled {
	margin-bottom: 15px;
	> li {
		margin-bottom: 7px;
		a {
			font-size: 15px;
			text-decoration: none;
			&:hover, &.active {
				text-decoration: underline;
				color: $black-color; } }
		> ul {
			margin-left: 15px;
			> li {
				&:before {
					content: '-';
					margin-right: 3px; }
				> a {
					font-size: 13px; } } } } }

.panel-manage-products {
	margin-bottom: 20px;
	padding: 10px 10px 8px 10px;
	background-color: $light-grey-color;
	.display-button {
		button {
			border: none;
			background: none;
			padding: 0;
			display: inline-block;
			vertical-align: middle;
			.fa {
				font-size: 23px;
				line-height: 1;
				color: $black-color; }
			+ button {
				margin-left: 7px; }
			&.active {
				.fa {
					color: $accent-color; } } } }
	.sort-limit {
		> * {
			display: inline-block;
			vertical-align: bottom;
			select {
				max-width: 150px; } }
		.limit {
			margin-left: 10px; } }
	.compare {
		text-align: right;
		a {
			display: inline-block;
			vertical-align: middle; } } }
.list-product-page {
	margin-bottom: 15px; }

ul.pagination {
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		display: inline-block;
		+ li {
			margin-left: 7px; }
		a, span {
			display: inline-block;
			font-size: 18px;
			color: $black-color;
			text-decoration: none;
			padding: 2px 6px 1px 6px;
			line-height: 1; }
		a {
			border: 2px $black-color solid; }

		a:hover {
			background-color: $black-color;
			color: $white-color; } } }

.product-info {
	.main-image {
		margin-bottom: 10px;
		img {
			max-width: 100%; } }
	.image-additional-item {
		a {
			display: block;
			text-align: center;
			border-top: 1px solid transparent;
			padding-top: 5px;
			padding-left: 5px;
			padding-right: 5px;
			&.active {
				border-top: 1px solid $accent-color; } } }
	.find-cheaper {
		margin-bottom: 10px;
		a {
			text-decoration: none; } }
	.right-panel {
		position: relative;
		.btn.btn-grey.btn-compare {
			position: absolute;
			right: 15px;
			top: 0;
			text-transform: none;
			border-bottom: 1px solid;
			padding: 0;
			padding-bottom: 3px;
			&:before {
				content: '';
				font-family: fontawesome;
				margin-right: 3px; } }
		.price {
			margin-bottom: 10px;
			font-size: 30px;
			color: $accent-color;
			&-old {
				font-size: 18px;
				color: $black-color;
				position: relative;
				z-index: 1;
				&:after {
					content: '';
					height: 1px;
					background-color: $red-color;
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					z-index: 1;
					transform: translateY(-50%); } }
			&-new {
				color: $red-color; } } }
	.product-action-one {
		margin-bottom: 15px;
		> div {
			display: inline-block; }
		input {
			width: 50px;
			text-align: center; }
		button.plus, button.minus {
			border: none;
			background: none; }
		.button-cart {
			margin-left: 10px; } }
	.product-action-two {
		margin-bottom: 15px;
		.btn {
			padding: 0;
			color: $accent-color;
			font-weight: 400;
			font-size: 13px;
			&:hover {
				color: $black-color; }
			& + .btn {
				margin-left: 7px; } }
		.btn-rassrochka {
			position: relative;
			span, i {
				position: absolute; }
			span {
				bottom: 25px;
				right: 0;
				width: 100%;
				background-color: $accent-color;
				color: #fff;
				font-size: 12px;
				padding: 3px 10px;
				display: none;
				text-transform: none;
				&:before {
					position: absolute;
					bottom: -4px;
					right: 2px;
					content: "";
					border-top: 4px solid $accent-color;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent; } }
			i {
				top: -7px {
  			right: 2px; }
				&:hover + span {
					display: block; } } } } }

.product-attribute {
	margin-bottom: 7px;
	table {
		width: 100%;
		tr {
			td {
				width: 49%;
				padding: 2.5px 0;
				&:last-of-type {
					text-align: center;
					font-size: 13px; } } } } }
.product-description {
	margin-top: 15px;
	&-content {
		h1, h2, h3 {
			color: $accent-color; } } }
iframe {
	width: 100%!important; }

.product-download {
	margin-bottom: 15px; }
.alert {
	margin-bottom: 20px;
	border: 1px $black-color solid;
	padding: 15px 35px 15px 15px;
	position: relative;
	background-color: $white-color;
	button {
		background: none;
		border: none;
		position: absolute;
		right: 5px;
		top: 5px; }
	&-danger {
		border-color: $red-color; } }
.table {
	width: 100%;
	margin-bottom: 20px;
	&.table-bordered {
		td {
			padding: 5px;
			background-color: $white-color; }
		tr:nth-child(even) {
			td {
				background-color: lighten($black-color, 85%); } }
		thead {
			td {
				font-weight: 500; } } } }

.module-product-block {
	margin-bottom: 15px; }
div.table-responsive {
	overflow-x: auto; }

.flycart {
	&-heading {
		h3 {
			margin-bottom: 15px; }
		p {
			margin: 0;
			margin-bottom: 15px; } }

	.products-item {
		border-top: 1px #E8E6E9 solid;
		padding-top: 10px;
		margin-bottom: 10px;
		.image {
			text-align: center;
			img {
				max-width: 100%; } }
		.name {
			margin-bottom: 2px;
			text-align: left;
			a {
				text-decoration: none;
				text-transform: uppercase;
				color: $black-color;
				&:hover {
					color: $accent-color; } } }
		.stock,
		.quantity {
			color: lighten($black-color, 50%); }
		.stock {
			text-align: left; }
		.total {
			white-space: nowrap; } }

	&-buttons {
		border-top: 1px #E8E6E9 solid;
		padding-top: 15px;
		display: flex;
		justify-content: space-between; }

	.gproducts {
		border-top: 1px #cececc solid;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		&-card {
			margin: 5px;
			width: 240px;
			.name {
				margin-bottom: 5px; } } } }


.compare-widget {
	a {
		position: fixed;
		text-decoration: none;
		right: 0;
		top: 25%;
		background-color: #fff;
		opacity: .6;
		box-shadow: 0 1px 3px #cecece;
		border: 1px $accent-color solid;
		border-right: none;
		padding: 5px 7px;
		z-index: 1;
		.fa, span {
			display: block;
			text-align: center; }
		.fa {
			margin-bottom: 7px;
			font-size: 20px; }
		&:hover {
			opacity: 1; } } }


.simplecheckout-cart {
	margin-bottom: 20px;
	&-products {
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			border-bottom: 1px lighten($black-color, 20%) solid;
			padding-bottom: 15px;
			> * {
				padding: 5px; }
			.product {
				width: 50%;
				.image {
					float: left; }
				.meta {
					margin-left: 120px;
					.name {
						margin-bottom: 5px;
						a {
							text-decoration: none;
							color: $black-color;
							font-size: 16px;
							&:hover {
								border-bottom: 1px solid; } } }
					.model {
						margin-bottom: 10px;
						font-size: 13px; }
					.price {
						color: $accent-color;
						font-size: 18px;
						span {
							color: lighten($black-color, 20%);
							font-size: 13px; } } } }
			.quantity,
			.total,
			.remove {
				text-align: center; }
			.quantity {
				width: 30%;
				white-space: nowrap;
				button {
					background: none;
					border: none; }
				input {
					width: 40px;
					text-align: center; } }
			.total {
				width: 15%;
				font-size: 20px;
				white-space: nowrap;
				color: $accent-color;
				span {
					font-weight: 300;
					font-size: 13px;
					color: $black-color; } }
			.remove {
				width: 5%;
				button {
					background: none;
					border: none;
					.fa {
						font-size: 18px;
						color: $red-color; } } } } }
	&-modules {
		display: table;
		width: 100%;
		> * {
			display: table-cell;
			padding: 5px;
			&:first-child {
				text-align: right;
				width: 85.5%; } }
		input.form-control {
			width: auto; } }
	&-totals {
		display: table;
		width: 100%;
		> div {
			display: table-row;
			> span {
				display: table-cell;
				padding: 5px;
				&:first-child {
					text-align: right;
					width: 85.5%; }
				&:last-of-type {
					white-space: nowrap;
					color: $black-color;
					font-size: 17px; } } }
		#total_total {
			font-size: 16px;
			span {
				&:last-of-type {
					color: $red-color;
					font-size: 19px;
					font-weight: 600; } } } } }

#simplecheckout_shipping_address,
#simplecheckout_comment {
	margin-top: 15px; }
#agreement_checkbox {
	margin-bottom: 10px; }

.mfp-ajax-holder .mfp-content {
	max-width: 600px;
	width: 100%;
	background-color: #fff; }
.wrapper-popup-form {
	> div {
		padding: 15px;
		text-align: center;
		p {
			text-align: left; } }
	h2 {
		text-align: center;
		color: $black-color; }
	.buttons {
		margin-top: 15px; }
	.compare-info {
		padding: 30px; } }
form {
	.buttons {
		margin-top: 10px; } }
.form-group {
	&.required {
		label {
			&:after {
				content: '*';
				color: $red-color;
				margin-left: 3px; } } }
	label {
		display: block;
		margin-bottom: 3px; }
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="password"],
	textarea {
		display: block;
		width: 100%; }
	& + & {
		margin-top: 10px; } }

.simpleregister-block-content {
	padding: 0; }

.contact-page-contacts {
	margin-bottom: 20px;
	.line {
		.icon {
			float: left;
			.fa {
				font-size: 16px;
				width: 25px;
				line-height: 25px;
				text-align: center;
				color: $accent-color; }
			+ .content {
				margin-left: 30px; } }
		+ .line {
			margin-top: 15px; } } }
.contact-page-form {
	margin-top: 10px;
	h3 {
		margin-bottom: 10px; } }
.search-parameters {
	margin-bottom: 20px; }
.scroll-to-top {
	position: fixed;
	bottom: 10px;
	right: 15px;
	display: none;
	z-index: 1; }
.footer {
	margin-top: 15px;
	background-color: $light-grey-color;
	padding-top: 15px;
	h4 {
		font-weight: normal;
		margin: 0;
		margin-bottom: 7px;
		text-transform: uppercase; }
	ul {
		li {
			a {
				text-decoration: none;
				color: $black-color;
				&:hover {
					color: $accent-color; } } } }
	&-top {
		margin-bottom: 15px; }
	.contact-info {
		p {
			margin: 0;
			margin-bottom: 3px; } }
	.powered {
		margin-right: 15px; } }
@import "media"; // Всегда в конце
